<template>
    <div class="purchaseSecond"> 
        <el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/server/server' }">业务中心</el-breadcrumb-item>
			<el-breadcrumb-item>上传凭证</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="certificateInfo">
			<div class="topTitle">
				<span class="title">上传凭证</span>
			</div>
			<div class="content">
				<el-form :model="ruleForm"  ref="ruleForm" label-width="120px" class="demo-ruleForm authentication-form">
					<el-form-item label="订单编号">
					    <div>{{buyDetail.id}}</div>
					</el-form-item>
					<el-form-item label="付款信息">
					    <div class="cardCode">
							<div>卡号：{{card_code}}</div>
						</div>
						<div class="cardCode">姓名：刘学奇</div>
						<div class="cardCode">开户行：农业银行潍坊奎文东郊支行</div>
					</el-form-item>
					<el-form-item label="上传凭证">
					    <el-upload
							:on-success="uploadSuccess"
							list-type="picture-card"
							:on-preview="handlePictureCardPreview"
							:on-remove="handleRemove"
							multiple
							:limit="5"
							:action="uploadUrl"
							:data="postData"
						>
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>
					<el-form-item label="备注(选填)">
						<div class="textArea">
							<el-input type="textarea" placeholder="备注(选填)" v-model="ruleForm.paymentDetail" show-word-limit>
							</el-input>
						</div>
					</el-form-item>
					<div class="confirmBtn">
						<el-button type="primary" @click="onPay()">提交</el-button>
					</div>
                </el-form>
			</div>
		</div>
    </div>
	
</template>

<script>
import {Buy} from '@/api/index';
export default {
	data() {
		return {
			card_code:'6228480296778756266',
			buyDetail: {},
			ruleForm:{
				id: '',
				paymentUrl: '',
				paymentDetail: ''
			},
			images:[],
			dialogImageUrl: '',
        	dialogVisible: false,
		}
	},
	created() {
		this.id = this.$route.query.id;
		this.ruleForm.id = Number(this.id);
		this.secondInfo();
	},
	methods:{
		async secondInfo() {
				let {
					status,
					data
				} = await Buy.buyInfo({
					id: this.id
				});
				if (status) {
					if (data.code == 0) {
						this.buyDetail = data.data.Detail
					} else {
						this.$message.error(data.message)
					}
				}
			},
			//上传凭证
			handleRemove(file) {
				let i = file.response.data
				this.images = this.images.filter(item => item != i)
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			uploadSuccess(response){
				this.images.push(response.data.path)
			},
			//提交
			async onPay() {
				if (this.images.length == 0) {
					this.$message.error('请选择付款凭证上传')
					return
				}
				this.ruleForm.paymentUrl = this.images
				let {
					status,
					data
				} = await Buy.pay(this.ruleForm);
				if (status) {
					if (data.code == 0) {
						this.$message.success('操作成功')
						this.$router.replace('/server/server')
					} else {
						this.$message.error(data.message)
					}
				}
			},
	},
	computed:{
        uploadUrl() {
            return this.$base_url + 'api/v1/upload/payment';
        },
        postData(){
		    return {token:localStorage.getItem('token')}
		}
    },
	watch:{
        images(){
            this.ruleForm.paymentUrl = this.images.join(',')
        }
    }
}
</script>

<style lang="less">
    .purchaseSecond {
		body {
			margin: 0;
			padding: 0;
			background-color: #F0F3FA;
		}
		.el-breadcrumb {
			padding: 30px;
			.el-breadcrumb__inner a,
			.el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}
			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}
		.certificateInfo {
			min-height: 933px;
        	margin: 0 20px;
        	background-color: #ffffff;
			.topTitle {
				height: 58px;
				background-color: #F8F8F8;
				padding-top: 15px;
				padding-left: 30px;
				box-sizing: border-box;
				.title {
					font-size: 20px;
					color: #333333;
					font-weight: 600;
					border-left: 3px solid #1493FA;
					padding-left: 10px;
					margin-top: 20px;
				}
       	 	}
			.content {
				.btn_bind {
					width: 50%;
					display: flex;
					justify-content: center;
				}
				.cardCode {
					font-size: 18px;
					color: #606266;
				}
				.textArea {
					width: 70%;
				}
				.authentication-form {
					margin-top: 39px;
					.el-form-item__content {
						margin-left: 130px!important;
					}
					.el-form-item {
						margin-left: 30px;
					}
				}
				.confirmBtn {
					width: 70%;
					text-align: center;
				}
        	}
		}
    }
</style>